import {openModal} from '@Components/popup-modal/popup-modal-reducer';
import {SocialPostEvent} from '@Components/social-media/social-media.types';
import {clearCampaignsGrid} from "@Components/email-marketing-campaigns/email-marketing-campaigns-slice.ts";

/**
 * This library contains different functions to be used inside myStuff
 * Functions added here can either be common functions that are used across myStuff or functions that are to be used
 * by components but the amount of code is causing components to become too big, and hence, hard to read/navigate.
 * @author Muhammad Shahrukh <shahrukh@250mils.com>
 */

/**
 * the main content container for myStuff
 * @type {jQuery}
 * @private
 */
const myStuffRoot_ = $('#ms'),
    content_ = $('#content'),
    contextualOpts_ = $('#contextual-opts'),
    breadCrumbs_ = $('#breadcrumbs'),
    loader_ = $('.js-mystuff-loader');

export const PORTAL_CONTAINER_MY_STUFF_ID = 'portal-container-mystuff';

export const PMW_SUPPORT_URL = 'https://support.postermywall.com/hc/en-us';

/**
 * Object storing different user types
 * @type {Object}
 */
export const USER_TYPES = {
    designer: 'designer',
    teacherDesigner: 'teacher-designer',
    teacher: 'teacher',
    student: 'student',
    reseller: 'reseller',
    normal: 'normal',
};

/**
 * Returns true if social media v2 is enabled
 * @returns {boolean}
 */
export const isSocialMediaV2Enabled = () => {
    return myStuffRoot_.attr('data-is-social-media-v2-enabled') === '1';
};

// Keeping this here, might need to use if we decide to have a accounts tab/specific banner
export const doesUserHaveSocialMediaAccounts = () => {
    return myStuffRoot_.attr('data-does-user-have-social-media-accounts') === '1';
};

export const isEmailCampaignsReactPageEnabled = () => {
    return !!window.reactPageProps?.isEmailCampaignsReactPageEnabled;
};

export const isBusinessProfilesReactPageEnabled = () => {
    return !!window.reactPageProps?.isBusinessProfilesEnabled;
};

/**
 * Object storing all the hashes of the pages supported in myStuff
 */
export const MY_STUFF_HASHES = {
    HASH_INDEX: 1,
    myDesigns: '#/designs',
    sharedWithMe: '#/shared',
    favorites: '#/favorites',
    rejectedTemplates: '#/rejected-templates',
    search: '#/search',
    teamSpace: '#/team-space',
    brands: '#/brands',
    socialPosts: '#/posts',
    socialAccounts: '#/social-accounts',
    contentPlanner: '#/content-planner',
    emailCampaigns: '#/email-marketing-campaigns',
    emailCampaignPerformance: '#/email-marketing-campaign-performance',
    emailCampaignAudience: '#/email-marketing-campaign-audience',
    emailCampaignSettings: '#/email-marketing-campaign-settings',
    trash: '#/trash',
    trashSocialPosts: '#/trash-posts',
    trashEmailCampaigns: '#/trash-email-marketing-campaigns',
    designerInsights: '#/insights',
    designerActivity: '#/activity',
    designerEarnings: '#/earnings',
    designTips: '#/designtips',
    classProjects: '#/class-projects',
    keepClassroomsFree: '#/keep-free',
    myClients: '#/my-clients',
    recommendedForYou: '#/recommended',
    improveTemplates: '#/improve-templates',
    businessProfile: '#/business-profile',
};

/**
 * contains all the hashes/pages for My Stuff that have been fully implemented in React.
 * @type {Readonly<Object>}
 */
export const MY_STUFF_REACT_PAGES = Object.freeze({
    [MY_STUFF_HASHES.brands]: 1,
    [MY_STUFF_HASHES.contentPlanner]: 1,
    [MY_STUFF_HASHES.socialPosts]: Number(isSocialMediaV2Enabled()),
    [MY_STUFF_HASHES.socialAccounts]: Number(isSocialMediaV2Enabled()),
    [MY_STUFF_HASHES.trashSocialPosts]: Number(isSocialMediaV2Enabled()),
    [MY_STUFF_HASHES.emailCampaignPerformance]: 1,
    [MY_STUFF_HASHES.emailCampaignAudience]: 1,
    [MY_STUFF_HASHES.emailCampaignSettings]: 1,
    [MY_STUFF_HASHES.designerEarnings]: 1,
    [MY_STUFF_HASHES.emailCampaigns]: Number(isEmailCampaignsReactPageEnabled()),
    [MY_STUFF_HASHES.trashEmailCampaigns]: Number(isEmailCampaignsReactPageEnabled()),
    [MY_STUFF_HASHES.businessProfile]: Number(isBusinessProfilesReactPageEnabled()),
});

/**
 * the data-for values for subnav contextual opts in my stuff
 * @type {Readonly<Object>}
 */
export const MY_STUFF_CONTEXTUAL_OPTS_VALUES = Object.freeze({
    DEFAULT: 'all',
    REACT_CONTEXTUAL_OPTS: 'react-contextual-opts',
});

/**
 * General Async fail handler
 * @private
 */
export const onAsycFail_ = () => {
    alert(i18next.t('pmwjs_general_ajax_error'));
};

export const mapPopupModalDispatchToProps = (dispatch) => {
    return {
        openModal: (modalType) => dispatch(openModal(modalType)),
    };
};

export const mapPopupModalStateToProps = (state) => {
    return {
        isPopupModalOpen: state.popupModal.isPopupModalOpen,
        popupModalType: state.popupModal.popupModalType,
    };
};

export const redirectToMyDesigns = () => {
    window.location.href = PMW.util.site_url(`posters/mine${MY_STUFF_HASHES.myDesigns}`);
};

/**
 * Generic helper function to determine whether a modal relevant to a component opened or close on re-render
 * @param {Object} currentProps
 * @param {Object} nextProps
 * @param {string} modalType
 * @return {boolean}
 */
export const isPopupModalOpenOrClosed = (currentProps, nextProps, modalType) => {
    return nextProps.popupModalType !== currentProps.popupModalType && (nextProps.popupModalType === modalType || currentProps.popupModalType === modalType);
};

/**
 * Returns a boolean whether content is in empty state or not
 */
export const isInEmptyState = () => {
    return content_.find('.empty').length && !content_.find('.empty').hasClass('_hidden');
};

/**
 * Success handler for onEmptyTrash_
 * @param {object} r Response data
 * @private
 */
const onEmptyTrashSuccess_ = (r) => {
    switch (r.status) {
        case 'success':
            content_.find('.poster-grid .item').remove();
            content_.find('.folders .item').remove();
            content_.find('.empty').removeClass('_hidden');

            break;
        case 'error':
            alert(i18next.t('pmwjs_error_emptying_trash'));
            break;
        case 'notloggedin':
            alert(i18next.t('pmwjs_log_in_to_empty_trash'));
            break;
    }
};

/**
 * Updates the trash view, shows or hides the empty trash message and the empty trash button
 * @private
 */
const updateTrashView_ = () => {
    const isEmptyState = isInEmptyState();

    if (isEmptyState) {
        content_.find('.empty').removeClass('_hidden');
    } else {
        content_.find('.empty').addClass('_hidden');
    }
};

/**
 * Permanently deletes the logically deleted social media posts
 * @private
 */
export const onEmptyPostTrash = () => {
    PMW.setLoading(i18next.t('pmwjs_deleting'));

    PMW.write(PMW.util.site_url('socialmedia/emptyTrashForUser'), {}, function (d) {
        if (d.status === 'success') {
            window.dispatchEvent(new Event(SocialPostEvent.RESET_GRID));
            content_.find('.grid-item-heading').remove();
            content_.find('.post-list-grid[data-type="deleted"]').html('');
            $('.my-stuff-post-list .empty').removeClass('_hidden');
            PMW.setLoading(null);
        } else {
            PMW.setLoading(i18next.t('pmwjs_error_message'));
            setTimeout(function () {
                PMW.setLoading(null);
            }, 2000);
        }
    });
};

/**
 * Logically permanently deletes the email marketing campaigns
 * @private
 */
export const onEmptyCampaignTrash = () => {
    PMW.setLoading(i18next.t('pmwjs_deleting'));

    PMW.write(PMW.util.site_url('emailmarketing/emptyTrashForUser'), {}, function (d) {
        if (d.status === 'success') {
            window.PMW.redux.store.dispatch(clearCampaignsGrid());
            content_.find('.grid-item-heading').remove();
            content_.find('.email-campaign-grid').html('');
            $('.my-stuff-campaign-list .empty').removeClass('_hidden');
            PMW.setLoading(null);
        } else {
            PMW.setLoading(i18next.t('pmwjs_error_message'));
            setTimeout(function () {
                PMW.setLoading(null);
            }, 2000);
        }
    });
};

/**
 * Handler for the Empty Trash button
 * @private
 */
export const onEmptyTrash = () => {
    PMW.setLoading(i18next.t('pmwjs_emptying_trash'));

    PMW.write(PMW.util.site_url('posters/emptyTrash'), {}).done(onEmptyTrashSuccess_).fail(onAsycFail_).always(PMW.setLoading.bind(this, null));
};

/**
 * Given a Ref (React.Ref) to a tabbed component and its slider, calculate the tab slider's styles and position the tab slider
 * according to the tab currently active
 * @param {React.Ref} tabBarRef the React ref object for the tabBar root element
 * @param {React.Ref} tabSliderRef the React ref object for the tabSlider element itself
 */
export const calculateTabSliderStyles = (tabBarRef, tabSliderRef) => {
    const tabBarElement = tabBarRef.current,
        tabSliderElement = tabSliderRef.current,
        activeTab = tabBarElement.querySelector('.tab-active');

    tabSliderElement.style.bottom = 0;
    tabSliderElement.style.left = activeTab.offsetLeft + 'px';
    tabSliderElement.style.width = activeTab.offsetWidth + 'px';
};

/**
 * sets the #contextual-opts data-for attribute to the given feature name (folder, posts, teams, etc)
 * @param {string} featureName the current page or feature name to give to contextual opts. e.g. data-for = folders
 */
export const setContextualOptsFeatureName = (featureName) => {
    contextualOpts_.attr('data-for', featureName);
};

export const cleanUpMyStuffSubnavContent = () => {
    cleanUpBreadCrumbs();
    cleanUpContextualOpts();
};

export const resetMyStuffPageTitle = () => {
    document.title = `${myStuffRoot_.attr('data-default-title')} | PosterMyWall`;
};

/**
 * Reverts the My Stuff contextual Opts to its default state using the data-for attribute
 */
export const cleanUpContextualOpts = () => {
    contextualOpts_.attr('data-for', MY_STUFF_CONTEXTUAL_OPTS_VALUES.DEFAULT);
};

export const cleanUpBreadCrumbs = () => {
    breadCrumbs_.children().remove();
};

/**
 * depending on the given trash page, return the appropriate handler for deleting all the items on that page
 * @param {string} pageHash
 */
export const getAppropriateEmptyTrashHandler = (pageHash) => {
    switch (pageHash) {
        case MY_STUFF_HASHES.trash:
            return onEmptyTrash;

        case MY_STUFF_HASHES.trashSocialPosts:
            return onEmptyPostTrash;

        case MY_STUFF_HASHES.trashEmailCampaigns:
            return onEmptyCampaignTrash;
    }
};

export const isReactMyStuffPage = (pageHash) => {
    const key = getMyStuffPageHash(pageHash);
    return MY_STUFF_REACT_PAGES.hasOwnProperty(key) && MY_STUFF_REACT_PAGES[key];
};

/**
 * Returns true if the passed pageHash is for the Team page in My Stuff
 * @param {string} windowLocationHash
 * @return {boolean}
 */
export const isTeamSpacePage = (windowLocationHash) => {
    return getMyStuffPageHash(windowLocationHash) === MY_STUFF_HASHES.teamSpace;
};

/**
 * Returns true if the passed pageHash is for the Favorites page in My Stuff
 * @param {string} windowLocationHash
 * @return {boolean}
 */
export const isFavoritesPage = (windowLocationHash) => {
    return getMyStuffPageHash(windowLocationHash) === MY_STUFF_HASHES.favorites;
};

/**
 * isRejected true if the passed pageHash is for the rejected templates page in My Stuff
 * @param {string} windowLocationHash
 * @return {boolean}
 */
export const isRejectTemplatesPage = (windowLocationHash) => {
    return getMyStuffPageHash(windowLocationHash) === MY_STUFF_HASHES.rejectedTemplates;
};

/**
 * Returns true if the passed windowLocationHash is for one of the email marketing
 * campaigns dashboard pages, and false otherwise.
 * @param windowLocationHash
 * @return {boolean|boolean}
 */
export const isEmailCampaignsDashboardPage = (windowLocationHash) => {
    const hash = getMyStuffPageHash(windowLocationHash);
    return (
        hash === MY_STUFF_HASHES.emailCampaignPerformance ||
        hash === MY_STUFF_HASHES.emailCampaignSettings ||
        hash === MY_STUFF_HASHES.emailCampaignAudience ||
        hash === MY_STUFF_HASHES.emailCampaigns
    );
};

/**
 * Returns true if the passed windowLocationHash is for one of the social media publishing pages
 * @param windowLocationHash
 * @return {boolean|boolean}
 */
export const isSocialMediaPublishingPage = (windowLocationHash) => {
    const hash = getMyStuffPageHash(windowLocationHash);
    return hash === MY_STUFF_HASHES.socialPosts || hash === MY_STUFF_HASHES.socialAccounts;
};

/**
 * Extracts and returns the page hash from the passed window location hash, such
 * that it can be compared with one of the constants from MY_STUFF_HASHES.
 * @return {string}
 */
export const getMyStuffPageHash = (windowLocationHash) => {
    return '#/' + windowLocationHash.split('/')[MY_STUFF_HASHES.HASH_INDEX];
};

/**
 * Returns true if the email campaign metrics dashboard is enabled for non super-users,
 * and false otherwise.
 * @return {boolean}
 */
export const isEmailMarketingCampaignMetricsDashboardEnabled = () => {
    return myStuffRoot_.attr('data-is-emc-metrics-enabled') === '1';
};

/**
 * Returns true if the audiences view is enabled, and false otherwise.
 * @return {boolean}
 */
export const isEmailMarketingCampaignAudiencesViewEnabled = () => {
    return myStuffRoot_.attr('data-is-audiences-view-enabled') === '1';
};

/**
 * Returns true if the content planner is enabled, false otherwise.
 * @return {boolean}
 */
export const isContentPlannerEnabled = () => {
    return myStuffRoot_.attr('data-is-content-planner-enabled') === '1';
};

/**
 * Returns true if the business profiles are enabled, false otherwise.
 * @return {boolean}
 */
export const isBusinessProfilesEnabled = () => {
    return myStuffRoot_.attr('data-is-business-profiles-enabled') === '1';
};

/**
 * @param {string} pageHash
 * @return {boolean}
 */
export const isMyDesignsView = (pageHash) => {
    return (
        pageHash === MY_STUFF_HASHES.myDesigns ||
        pageHash === MY_STUFF_HASHES.search ||
        pageHash === MY_STUFF_HASHES.sharedWithMe ||
        pageHash === MY_STUFF_HASHES.favorites ||
        (pageHash === MY_STUFF_HASHES.rejectedTemplates && shouldShowRejectTemplatesPage())
    );
};

/**
 * @param {string} pageHash
 * @return {boolean}
 */
export const isDesignerDashboardPage = (pageHash) => {
    return (
        pageHash === MY_STUFF_HASHES.designerInsights ||
        pageHash === MY_STUFF_HASHES.designerActivity ||
        pageHash === MY_STUFF_HASHES.designerEarnings ||
        pageHash === MY_STUFF_HASHES.designTips
    );
};

/**
 * @param {string} pageHash
 * @return {boolean}
 */
export const isTrashPage = (pageHash) => {
    return pageHash === MY_STUFF_HASHES.trash || pageHash === MY_STUFF_HASHES.trashEmailCampaigns || pageHash === MY_STUFF_HASHES.trashSocialPosts;
};

/**
 *
 * @param pageHash
 * @return {boolean}
 */
export const isMyDesignsPage = (pageHash) => {
    return pageHash === MY_STUFF_HASHES.myDesigns;
};

/**
 * @param {string} pageHash
 * @return {boolean}
 */
export const isContentPlannerPage = (pageHash) => {
    return pageHash === MY_STUFF_HASHES.contentPlanner;
};

/**
 *
 * @param pageHash
 * @returns {boolean}
 */
export const isEmailCampaignsAudiencePage = (pageHash) => {
    return pageHash === MY_STUFF_HASHES.emailCampaignAudience;
};

export const isValidHashParams = (hashParams) => {
    return hashParams.substr(1);
};

/**
 *
 * @param {string} pageHash
 * @return {boolean}
 */
export const isResellerClientsPage = (pageHash) => {
    return pageHash === MY_STUFF_HASHES.myClients;
};

/**
 *
 * @param pageHash
 * @return {boolean}
 */
export const isPageAccessibleToTeachersOnly = (pageHash) => {
    return pageHash === MY_STUFF_HASHES.classProjects || pageHash === MY_STUFF_HASHES.keepClassroomsFree;
};

/**
 * Checks whether the Reject Templates page should be visible to the user or not
 * @return {boolean}
 */
export const shouldShowRejectTemplatesPage = () => {
    return myStuffRoot_.attr('data-show-reject-templates') === '1';
};

export const shouldRejectNotificationBeShown = () => {
    return shouldShowRejectTemplatesPage() && myStuffRoot_.attr('data-reject-notification') === '1';
};

export const showMyStuffLoader = () => {
    loader_.removeClass('_hidden');
};

export const hideMyStuffLoader = () => {
    loader_.addClass('_hidden');
};
