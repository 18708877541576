import type {ReactElement} from 'react';
import type {SidebarPopoverItem} from '@Components/mystuff-left-sidebar/components/sidebar-popover';
import {SidebarPopover} from '@Components/mystuff-left-sidebar/components/sidebar-popover';
import React from 'react';
import {readCookie, setCookie} from '@Utils/cookie.util';
import {getAssetUrl} from '@Utils/s3.util';
import styles from './social-media-mystuff-sidebar-popover.module.scss';

export interface SocialMediaMystuffSidebarPopoverProps {
  isMobile: boolean;
}

export function SocialMediaMystuffSidebarPopover({isMobile}: SocialMediaMystuffSidebarPopoverProps): ReactElement | null {
  const HAS_SEEN_SOCIAL_MEDIA_POPUP = 'pmwsocialmediaintromultiposting';
  const sidebarPopoverId = 'pmw-social-media-intro-popover';

  const sideBarItems: SidebarPopoverItem[] = [
    {
      icon: 'icon-analytics',
      text: window.i18next.t('pmwjs_intro_social_media_point_one'),
    },
    {
      icon: 'icon-photos',
      text: window.i18next.t('pmwjs_intro_social_media_point_two'),
    },
  ];

  const setCookieOnClose = (): void => {
    setCookie(HAS_SEEN_SOCIAL_MEDIA_POPUP, '1', 180);
  };

  const cleanupPopoverOnClose = (): void => {
    const popover = document.getElementById(sidebarPopoverId);

    if (!popover) {
      return;
    }

    const popoverParent = popover.parentElement;

    if (!popoverParent) {
      return;
    }

    const popoverWrapperForPositioning = popoverParent.parentElement;

    if (!popoverWrapperForPositioning) {
      return;
    }

    popoverWrapperForPositioning.remove();
  };

  const onClose = (): void => {
    setCookieOnClose();
    cleanupPopoverOnClose();
  };

  const shouldShowIntroPopupSocialMedia = (): boolean => {
    const value = readCookie(HAS_SEEN_SOCIAL_MEDIA_POPUP);
    return !value;
  };

  return shouldShowIntroPopupSocialMedia() ? (
    <SidebarPopover
      arrowClasses={styles.arrowPositioningStyles}
      introAssetUrl={getAssetUrl('social-media-sidebar-popover.png')}
      onClose={onClose}
      sidebarItems={sideBarItems}
      assetClasses={isMobile ? styles.assetClassesMobile : styles.assetClasses}
      popupContentClasses={isMobile ? styles.mobilePopupClasses : ''}
      sidebarPopoverId={sidebarPopoverId}
      isMobile={isMobile}
    />
  ) : null;
}
