import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {FlatIconColor, FlatIconColorType, IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {IntroPopup} from '@Components/intro-popup';
import {ALIGNMENT_TYPE} from '@Components/dropdown-v2/dropdown.types';
import styles from './sidebar-popover.module.scss';

export interface SidebarPopoverProps {
  sidebarItems: SidebarPopoverItem[];
  onClose: VoidFunction;
  introAssetUrl: string;
  arrowClasses?: string;
  assetClasses?: string;
  popupContentClasses?: string;
  sidebarPopoverId?: string;
  isMobile?: boolean;
}

export interface SidebarPopoverItem {
  icon: string;
  text: string;
}

export function SidebarPopover({
  sidebarItems,
  onClose,
  introAssetUrl,
  arrowClasses = '',
  assetClasses = '',
  popupContentClasses = '',
  sidebarPopoverId,
  isMobile = false,
}: SidebarPopoverProps): ReactElement {
  const getSideBarPopoverItem = (sideBarItem: SidebarPopoverItem, index: number): ReactElement => {
    return (
      <li key={`sidebar-popover-point-${index}`} className={styles.listItem}>
        <Icon
          icon={sideBarItem.icon}
          className={styles.iconClasses}
          size={IconSize.SIZE_ICON_16}
          shape={IconShape.CIRCLE}
          type={IconType.FLAT}
          flatIconType={{color: FlatIconColor.PRIMARY, type: FlatIconColorType.DEFAULT}}
        />
        <Text className={styles.listPoints} dangerouslySetInnerHTML size={TextSize.XSMALL} val={sideBarItem.text} />
      </li>
    );
  };

  const getIntroPopupList = (): ReactElement => {
    const nItems = getNumberOfItems();
    const listItems = [];

    for (let i = 0; i < nItems; i++) {
      const sideBarItem = sidebarItems[i];
      listItems.push(getSideBarPopoverItem(sideBarItem, i));
    }

    return (
      <div className={isMobile ? styles.listItemsWrapperMobile : styles.listItemsWrapper}>
        <ul>{listItems}</ul>
      </div>
    );
  };

  const getNumberOfItems = (): number => {
    return sidebarItems.length;
  };

  return (
    <IntroPopup
      userHasSeenCallbackOnClose={(): void => {
        onClose();
      }}
      title={window.i18next.t('pmwjs_intro_social_media')}
      content={getIntroPopupList()}
      assetUrl={introAssetUrl}
      assetClasses={assetClasses}
      leftOffset={0}
      dropdownAlignment={ALIGNMENT_TYPE.RIGHT_START}
      shouldShift={false}
      shouldFlip={false}
      arrowClasses={arrowClasses}
      popupContentClasses={popupContentClasses}
      elementId={sidebarPopoverId === undefined ? undefined : sidebarPopoverId}
      isMobile={isMobile}
    />
  );
}
